.user-panel-card {
    width: 18rem;
    margin: 10px;
    padding: 15px;
    background-image: linear-gradient(to bottom right, #f8f9fa, #e9ecef);
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease;
    text-decoration: none;
  }
  
  .user-panel-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
    background-color: #e9ecef;
    text-decoration: none;
  }
  
  .user-panel-link {
    text-decoration: none;
    color: inherit;
  }
  
  .user-panel-image {
    object-fit: cover;
    height: 200px;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .user-panel-card:hover .user-panel-image {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    text-decoration: none;
  }
  
  .user-panel-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 10px;
    transition: color 0.3s ease;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    text-decoration: none;

  }
  
  .user-panel-title:hover {
    color: #007bff;
    text-decoration: none;
  }
  
  .user-panel-description {
    font-size: 1rem;
    font-weight: 400;
    padding-top: 5px;
    line-height: 1.5;
    text-decoration: none;

  }
  
  /* in your Panel.css file */
.hoverable-text {
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;  /* smooth transition for background and color changes */
}

.hoverable-text:hover {
  background-color: rgba(0, 0, 0, 0.05); /* light gray background on hover */
  color: #007BFF;  /* change text color to Bootstrap primary blue on hover */
}
