.grid-card {
    margin-bottom: 20px;
    max-width: 20rem;
 }

 .grid-card-disabled {
    pointer-events: none;
    opacity: 0.35;
 }

 .modal-90w {
    max-width: 90%;
 }