html {
    min-width: '1024px'
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'password';
    src: local('password'), url(./fonts/password.ttf) format('truetype');
}
  
#root_password {
    font-family: 'password';
}

.flex-inner {
    width: auto;
    height: 10em;
    overflow: auto;
  }
  
.card-img-top {
    background-color:black;
    opacity:0.5;
    object-fit: cover;
    height: inherit;
 }

.card-img-overlay {
    text-shadow: white 0px 0px 10px;
 }

 .result-grid {
    display: grid;
    grid-gap: 10px;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
    gap: 1em;
 }

 .result-box {
    border: 1px solid black;
    width: auto;
 }


 abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
  }

  .modal-dialog {
    max-width: 90% !important;
  }