.barcode {
    width: 3in;
    height: 1in;
    margin: 0 auto;
}

@media print {
    html, body {
        height: 99%;
        width: 99%;
        margin: 0;
        padding: 0;
    }

    @page {
        height: 3in;
        width: 1in;
        margin: 0;
        padding: 0;
        size: landscape;
    }

    body * {
        margin: 0;
        padding: 0;
    }

    .section-not-to-print, .section-not-to-print * {
        display: none;
        visibility: visible;
    }

    #section-to-print, #section-to-print * {
        visibility: visible;
    }

    #section-to-print {
        width: 100%;
        margin:0;
        padding:0;
        zoom: 135%;
    }

    .barcode {
        position: relative;
        right: 70%;
        margin: 0 auto;
        padding: 0;
    }
}