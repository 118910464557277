.icon-spin {
        -webkit-animation: icon-spin 2s infinite linear;
        animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
        0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
        }
}

@keyframes icon-spin {
        0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
        }
}


.reviewRequiredIcon:hover {
        filter: 'drop-shadow(rgb(150,115, 0) -1px 1px 0px) contrast(100%)'
}


.icon-pulse {
        -webkit-animation: icon-pulse 2s infinite linear;
        animation: icon-pulse 2s infinite linear;
}

@-webkit-keyframes icon-pulse {
        0% {
                -webkit-transform: scale(1);
                transform: scale(1);
                filter: unset
        }
        100% {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
                filter: 'drop-shadow(rgb(150,115, 0) -1px 1px 0px) contrast(100%)'
        }
}

@keyframes icon-pulse {
        0% {
                -webkit-transform: scale(1);
                transform: scale(1);
                filter: unset
        }
        100% {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
                filter: 'drop-shadow(rgb(150,115, 0) -1px 1px 0px) contrast(100%)'
        }
}
